import Cookies from 'js-cookie'
import { DEFAULT_LOCALE, LOCALE_COOKIE } from "~/constants/locales";


type LocalesPath = Record<string, () => Promise<{ default: Record<string, string> }>>

export function getLocalesInfo(): { locale: string, localePath: () => Promise<{ default: Record<string, string> }> } {

  const localesPath = getPathForLocales()
  const localesList = Object.keys(localesPath)
  const userLocale = readUserLocaleFromCookie(localesList)
  const userLocalePath = localesPath[userLocale]

  return {
    locale: userLocale,
    localePath: userLocalePath
  }
}


function getPathForLocales(): LocalesPath {
  const localesMap = Object.fromEntries(
    Object.entries(import.meta.glob('../locales/*.json')).map(([path, loadLocale]) => [
      transformLocaleName(path),
      loadLocale
    ])
  )
  return localesMap as LocalesPath
}

function transformLocaleName(path: string) {
  let matchedPath = path.match(/(\w*)\.json$/)?.[1]
  matchedPath = matchedPath.replace('_', '-')
  return matchedPath
}

function readUserLocaleFromCookie(localesList: string[]): string {
  const userLanguageInCookie = Cookies.get(LOCALE_COOKIE)
  const userLocale = localesList.find(locale => locale.includes(userLanguageInCookie))
  return userLocale || DEFAULT_LOCALE
}


