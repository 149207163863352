import API from '~/api/personal'
import APIHandler from '~/api/APIHandler'

export default new APIHandler(
  API,
  ({ profileId, promptForMarketingConsent, email, changedBy }) => {
    return {
      method: 'PUT',
      url: `profiles/${profileId}/marketingpreferences`,
      data: {
        email,
        promptForMarketingConsent,
        changedBy,
      },
    }
  },
  (response) => {
    return {
      ...response,
      statusCode: response.status,
    }
  }
)
