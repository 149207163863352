<template>
  <div class="InformationAppModal">
    <OenUiModal
      v-model="model"
      title=""
      @confirm="confirm"
      @cancel="closeModal"
    >
      <template #header>
        <OenUiModalHeader v-if="!noIcon">
          <template #default>
            <h1 v-if="title && noIcon">
              {{ title }}
            </h1>
            <div v-if="!noIcon">
              <IconWarningYellow v-if="icon === 'warningYellow'" />
              <IconMailBox v-if="icon === 'mailBox'" />
              <IconNoEntryRed v-if="icon === 'noEntryRed'" />
            </div>
          </template>
        </OenUiModalHeader>
      </template>

      <div class="modal-body">
        <slot />
      </div>

      <template #footer="{ confirm, cancel }">
        <div class="footerWrapper">
          <OenUiModalFooter>
            <slot
              name="footer"
              class="button-group"
              :confirm="confirm"
              :cancel="cancel"
            >
            </slot>
          </OenUiModalFooter>
        </div>
      </template>
    </OenUiModal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { useVModel } from '@oen.web.vue/ui'
import { IconWarningYellow, IconMailBox } from '@oen.web.vue2/icons'
import IconNoEntryRed from '~/assets/images/IconNoEntryRed.vue'


export default defineComponent({
  components: {
    IconWarningYellow,
    IconMailBox,
    IconNoEntryRed,
  },
  emits: ['close', 'confirm', 'update:modelValue'],
  props: {
    icon: {
      type: String,
      default: 'warning',
    },
    title: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
    },
    isLoading: {
      type: Boolean,
    },
    shouldDisplayCloseButton: {
      type: Boolean,
      default: false,
    },
    isCloseButtonMobileOnly: {
      type: Boolean,
      default: false,
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { modelValue: model } = useVModel(props, emit)

    const closeModal = () => {
      emit('close')
    }
    const confirm = () => {
      emit('confirm')
    }

    return { model, confirm, closeModal }
  },
})
</script>

<style scoped>
.title {
  @apply font-medium text-2xl leading-8;
}

.modal-body {
  @apply text-center;

  :slotted(h1) {
    @apply font-medium text-2xl leading-8;
  }
  :slotted(p) {
    @apply mt-4 ml-auto mr-auto text-secondary-text;
    max-width: 344px;
  }

  /* deep wasn't able to override the card classes */
  margin-top: -1.5rem;
  margin-bottom: -3rem;
}

:deep(.oen-ui-card-footer) {
  display: flex;
  flex-direction: column-reverse !important;
  justify-content: space-between;
  .oen-ui-button.oen-ui-button--secondary {
    @apply mt-4 !important;
  }
}

:deep(.oen-ui-card-footer > .oen-ui-button) {
  @apply w-full mt-2 !important;
}

:deep(.oen-ui-card > .oen-ui-card-header) {
  @apply mb-0 !important;
  @apply pb-4;
}
</style>
