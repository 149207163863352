export default class Country {
  constructor(country, isSupported = true) {
    this.name = country.text
    this.code = country.value
    this.canRegister = isSupported
    this.text= country.text
    this.value = country.value
    this.separator = false
  }
}
