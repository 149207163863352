import API from '~/api/launchpad'
import APIHandler from '~/api/LaunchpadAPIHandler'

export default new APIHandler(
  API,
  (profile, config) => {
    return {
      ...config,
      method: 'POST',
      url: `profiles`,
      data: profile,
    }
  },
  (response) => {
    return {
      ...response,
      profileData: response.data,
    }
  }
)
