// Pinia Store
import { defineStore } from 'pinia'
import { reduce, each } from 'lodash-es'
import { default as LaunchpadAPI } from '~/api/launchpad'
import { default as PersonalAPI } from '~/api/personal'
import axios from 'axios'
import { useBrandStore } from '~/store/brand'
import { brands } from '~/constants/idConstants'

const configKeys = {
  VUE_APP_ENDPOINTS_LAUNCHPADAPI: 'VUE_APP_ENDPOINTS_LAUNCHPADAPI',
  VUE_APP_URL_TRANSFER: 'VUE_APP_URL_TRANSFER',
  VUE_APP_URL_TOKEN: 'VUE_APP_URL_TOKEN',
  VUE_APP_URL_LOGIN: 'VUE_APP_URL_LOGIN',
  VUE_APP_KEYS_SEGMENT: 'VUE_APP_KEYS_SEGMENT',
  VUE_APP_KEYS_AMPLITUDE: 'VUE_APP_KEYS_AMPLITUDE',
  VUE_APP_XE_VERSION: 'VUE_APP_XE_VERSION',
  VUE_APP_ENV: 'VUE_APP_ENV',
  VUE_APP_KEYS_LOGROCKET_KEY: 'VUE_APP_KEYS_LOGROCKET_KEY',
  VUE_APP_ENDPOINTS_ACCOUNT_SITE: 'VUE_APP_ENDPOINTS_ACCOUNT_SITE',
  VUE_APP_URL_PUBLIC: 'VUE_APP_URL_PUBLIC',
  VUE_APP_URL_HELP_CENTRE_CREATE_ACCOUNT: 'VUE_APP_URL_HELP_CENTRE_CREATE_ACCOUNT',
  VUE_APP_URL_HELP_CENTRE: "VUE_APP_URL_HELP_CENTRE"
}

export const useEnvStore = defineStore('env', {
  // convert to a function
  state: () => ({
    // Return any VUE_APP environment variables
    ...reduce(
      import.meta.env,
      (acc, value, key) => {
        if (key.indexOf('VUE_APP_') === 0) {
          return {
            ...acc,
            [key]: value,
          }
        } else {
          return acc
        }
      },
      {}
    ),
    // ...configKeys
  }),
  getters: {
    getConfigKeys: (state) => {
      return configKeys
    },
    getConfigKey: () => (key) => {
      return configKeys[key]
    },
    getVariables: (state) => {
      return state
    },
    env: (state) => {
      return state
    },
    getVariable: (state) => (key) => {
      const value = configKeys[key]
      if (value === undefined || value === null) {
        // eslint-disable-next-line no-console
        console.warn('ENV: Variable is not registed in app: ' + key)
      }
      return configKeys[key]
    },
  },
  actions: {
    setVariables (variables) {
      // Loop through the required config keys, and ensure that each one is being accounted for
      each(configKeys, (value, key) => {
        let match = variables[key]
        if (match === undefined || match === null) {
          // eslint-disable-next-line no-console
          console.error(
            'ENV: Variable not defined for this environment: ' + key
          )
        } else {
          this[key] = match
        }
      })
    },
    // This is all that the api vuex store did
    setApi () {
      LaunchpadAPI.defaults.baseURL = this.VUE_APP_ENDPOINTS_LAUNCHPADAPI
      PersonalAPI.defaults.baseURL = this.VUE_APP_ENDPOINTS_LAUNCHPADAPI
    },
    async fetchVariables () {
      try {
        // new way in XE
        const { data } = await axios.get(`/env/config.json`)
        //Get Brand info before fetching any env files
        useBrandStore().getBrandFromDomain(data.default)
        const brand = useBrandStore().brandName
        useBrandStore().getBrandIdFromBrand(brand)
        let envVariables = { ...data.default }
        if (brand === brands.britline) {
          envVariables = { ...envVariables, ...data?.britline }
        } else if (brand === brands.jlp) {
          envVariables = { ...envVariables, ...data?.jlp }
        } else {
          envVariables = { ...envVariables, ...data.xe }
        }
        this.setVariables(envVariables)
      } catch (ex) {
        console.log(ex)
      }
    },
    async init () {
      await this.fetchVariables()
      this.setApi()
    },
  },
})
