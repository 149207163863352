import API from '~/api/launchpad'
import APIHandler from '~/api/LaunchpadAPIHandler'
import AddressDetails from '~/models/PersonalRegistration/AddressDetails/app'

export default new APIHandler(
  API,
  ({ addressId }) => {
    return {
      method: 'GET',
      url: `address/${addressId}`,
    }
  },
  ({ data: { items = [] } = {} } = {}) => {
    return {
      data: items.map((item) => new AddressDetails(item)),
    }
  }
)
