import API from '~/api/launchpad'
import APIHandler from '~/api/LaunchpadAPIHandler'
import State from '~/models/PersonalRegistration/State/app'

export default new APIHandler(
  API,
  (country) => {
    return {
      method: 'GET',
      url: `resources/countries/${country}/states`,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data.map((state) => {
        return new State(state)
      }),
    }
  }
)
