import API from '~/api/launchpad'
import APIHandler from '~/api/LaunchpadAPIHandler'

export default new APIHandler(
  API,
  ({ profileId, data }, config) => {
    return {
      ...config,
      method: 'PATCH',
      url: `profiles/${profileId}?platform=personalReg`,
      data: data,
    }
  },
  (response) => {
    return {
      ...response,
    }
  },
  (error) => {
    throw error
  }
)
