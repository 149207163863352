import API from '~/api/launchpad'
import APIHandler from '~/api/LaunchpadAPIHandler'
import AddressSearchResult from '~/models/PersonalRegistration/AddressSearchResult/app'

export default new APIHandler(
  API,
  ({ searchTerm, country, searchContext }) => {
    return {
      method: 'GET',
      url: `address?searchTerm=${searchTerm}&country=${country}${
        searchContext ? `&searchContext=${searchContext}` : ''
      }`,
    }
  },
  ({ data: { items = [] } = {} } = {}) => {
    return {
      data: items.map((item) => new AddressSearchResult(item)),
    }
  },
  (error) => {
    // Bad request due to PO Box
    if (
      error.response.status === 400 &&
      error.response.data.message.indexOf('PO Box') != -1
    ) {
      return error.response
    }

    // any other error to be handled downstream
    throw error
  }
)
