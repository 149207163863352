// Pinia Store
import { defineStore } from 'pinia'
import { every } from 'lodash-es'
import LogRocket, { sessionURL } from 'logrocket'
import LogRocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer'
import TrackConsentAllIntegrations from '~/constants/trackConsentIntegrations/all.const'
import TrackConsentEssentialIntegrations from '~/constants/trackConsentIntegrations/essential.const'
import Cookies from 'js-cookie'
import { useEnvStore as env } from '~/store/env'
import { useAppStore } from '~/store/appStore'
import { useBrandStore } from '~/store/brand'
import { useAuthStore } from '~/store/authStore'
import { SEGMENT_REFERENCE_SOURCE } from '~/constants/segmentAnalytics'
import { usePersonalRegistrationStore } from '~/store/personalRegistration'

let analyticsResolve = null
const analyticsPromise = new Promise((resolve) => {
  analyticsResolve = resolve
})
let trackingConsentResolve = null
export const trackingConsentPromise = new Promise((resolve) => {
  trackingConsentResolve = resolve
})

let logRocketResolve = null
const logRocketPromise = new Promise((resolve) => {
  logRocketResolve = resolve
})

export const useAnalyticsStore = defineStore('analytics', {
  // convert to a function
  state: () => ({
    dataLayer: [],
    paymentLocation: null,
    trackingCookieKey: 'xeConsentState',
    trackingConsent: null,
    identifyData: null,
    amplitudeSessionId: null,
    logRocketSessionUrl: null,
    emit: () => {},
  }),
  getters: {
    getTrackingConsentedToAll: (state) => {
      return every(state.trackingConsent)
    },
    getLogRocketSessionUrl: (state) => {
      return state.logRocketSessionUrl
    },
  },
  actions: {
    async init(emit) {
      //await dispatch('checkTrackingConsent')
      let performanceConsentValue = false
      const consentCookie = Cookies.get('xeConsentState')
      if (consentCookie !== undefined) {
        performanceConsentValue = JSON.parse(consentCookie)['performance']
      }
      if (performanceConsentValue) {
        this.initLogRocket()
        await this.fetchLogRocketSessionUrl()
      }
      this.initSegment()
    },

    async fetchLogRocketSessionUrl() {
      LogRocket.getSessionURL((sessionUrl) => {
        this.logRocketSessionUrl = sessionURL
        logRocketResolve()
        this.track({
          event: 'LogRocket',
        })
      })
    },
    async page(pageName) {
      await analyticsPromise
      window.analytics.page(pageName)
    },
    async identify({ userId, traits }) {
      await analyticsPromise
      //await trackingConsentPromise
      const prStore = usePersonalRegistrationStore()

      const brand = useBrandStore().brandName
      const userLanguage = prStore.getUserLocale
      const userLanguageCode = prStore.getUserPreferredLanguage
      const userLanguageCountryCode = userLanguageCode.toUpperCase()

      traits = {
        ...traits,
        brand,
        userLanguage,
        userLanguageCode,
        userLanguageCountryCode,
      }

      this.emit(`identify: ${userId}`, traits, { userId, traits })
      window.analytics &&
        window.analytics.identify &&
        window.analytics.identify(userId, traits, (event) =>
          this.getAmplitudeSessionId(event),
        )
    },
    async track({ event, traits = {} }) {
      await analyticsPromise
      await logRocketPromise
      const appStore = useAppStore()
      const brandStore = useBrandStore()
      const authStore = useAuthStore()

      // add traits common for all events
      if (brandStore.brandName === 'xe') {
        traits.referenceSource = SEGMENT_REFERENCE_SOURCE
      }
      traits.email = authStore.lastLogin
      traits.brand = brandStore.brandName
      traits.logRocketSessionURL = this.logRocketSessionUrl
      this.emit(`track: ${event}`, traits, { event, traits })
      try {
        window.analytics &&
          window.analytics.track(event, traits, (event) =>
            this.getAmplitudeSessionId(event),
          )
        console.log(event)
      } catch (ex) {
        appStore.logException({
          text: 'Failed to accept the event ' + event + ' from Personal Reg',
          exception: ex,
        })
      }
    },
    async gtmTrack({ event, variables }) {
      await analyticsPromise
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ event: event, ...variables })
    },
    async gtmTrackVariables({ variables }) {
      window.dataLayer = window.dataLayer || []

      for (let prop in variables) {
        // skip loop if the property is from prototype
        if (!variables.hasOwnProperty(prop)) continue
        window.dataLayer.push({ [prop]: variables[prop] })
      }
    },
    initSegment() {
      const TOKEN = env().VUE_APP_KEYS_SEGMENT
      // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/ajs-classic/#load-options

      const integrations = this.getTrackingConsentedToAll
        ? TrackConsentAllIntegrations
        : TrackConsentEssentialIntegrations

      // Segment
      // TODO copied from Segment like recommended eventually replace by our own loadScript ?
      !(function () {
        let analytics = (window.analytics = window.analytics || [])
        if (!analytics.initialize) {
          if (analytics.invoked) {
            window.console &&
              console.error &&
              console.error('Segment snippet included twice.')
          } else {
            analytics.invoked = !0
            analytics.methods = [
              'trackSubmit',
              'trackClick',
              'trackLink',
              'trackForm',
              'pageview',
              'identify',
              'reset',
              'group',
              'track',
              'ready',
              'alias',
              'debug',
              'page',
              'once',
              'off',
              'on',
              'addSourceMiddleware',
              'addIntegrationMiddleware',
              'setAnonymousId',
              'addDestinationMiddleware',
            ]
            analytics.factory = function (e) {
              return function () {
                let t = Array.prototype.slice.call(arguments)
                t.unshift(e)
                analytics.push(t)
                return analytics
              }
            }
            for (let e = 0; e < analytics.methods.length; e++) {
              let key = analytics.methods[e]
              analytics[key] = analytics.factory(key)
            }
            analytics.load = function (key, e) {
              let t = document.createElement('script')
              t.type = 'text/javascript'
              t.async = !0
              t.src =
                'https://cdn.segment.com/analytics.js/v1/' +
                key +
                '/analytics.min.js'
              let n = document.getElementsByTagName('script')[0]
              n.parentNode.insertBefore(t, n)
              analytics._loadOptions = e
            }
            analytics.SNIPPET_VERSION = '4.13.1'
            analytics.load(TOKEN, integrations)
            analyticsResolve()

            // getting the amplitude Deviceid from amp_xxx cookie
            // assumed (as per amplitude's doc) always the cookie name starts with amp_
            // we alwasys picked up the frist amp_xxx cookie
            let ampDeviceid = null
            const localCookies = Cookies.get()
            let cookieName = Object.keys(localCookies).find(
              (x) => x.indexOf('amp_') > -1,
            )
            if (cookieName) {
              ampDeviceid = localCookies[cookieName].split('.')[0]
            }
            console.log('Amplitude DeviceId: ' + ampDeviceid)
            if (ampDeviceid) {
              analytics.setAnonymousId(ampDeviceid)
            }
          }
        }
      })()
    },
    initLogRocket() {
      let settings

      // LogRocket running in dev mode may enter an infinite loop if a component throws an error
      // Does not affect deployed builds
      if (import.meta.hot) {
        console.log('LogRocket disabled in dev mode')
        return
      }

      if (import.meta.env.NODE_ENV === 'development') {
        settings = {
          release: import.meta.env.VUE_APP_VERSION + '-develop',
          dom: {
            isEnabled: true, //disable|enable all DOM recording
            inputSanitizer: false, // obfuscate all user-input elements <select> and <input>
          },
        }
      } else {
        const privateFieldNames = [
          'password',
          'cvv',
          'cvn',
          'name',
          'answer',
          'questions',
          'fingerprint',
          'token',
          'devicesimcard',
          'number',
          'mobilephone',
          'otp',
          'providedfields',
          'city',
          'state',
          'pin',
          'bankBicSwift',
          'email',
          'region',
          'Authorization',
          'verifieddevice',
          'bearer',
          'birth',
          'county',
          'place',
          'licence',
          'passport',
          'line1',
          'line2',
          'line3',
          'street',
          'district',
          'kycinformation',
          'location',
          'keys',
          'changedby',
          'zipcode',
          'subbuilding',
          'clientid',
          'clientnumber',
          'client_number',
          'province',
          'postalcode',
          'country',
          'area',
        ]
        const maskWholeWord = ['email', 'region', 'unit', 'idexpirydate']
        const { requestSanitizer, responseSanitizer } =
          LogRocketFuzzySanitizer.setup([...privateFieldNames])
        let pfn = privateFieldNames.filter((p) => maskWholeWord.indexOf(p) < 0) //  privatefieldnames array without the whole word match
        function sanitizeData(keyValue, key) {
          if (key !== 'undefined') {
            maskWholeWord.forEach((w) => {
              if (key.toString().toLowerCase() === w.toLowerCase()) {
                keyValue[key] = '*'
              } else {
                pfn.forEach((p) => {
                  if (key.toLowerCase().indexOf(p) !== -1) {
                    keyValue[key] = '*'
                  }
                })
              }
            })
          }
          return keyValue
        }
        const sanitizeHeader = (network) => {
          if (network.url.toLowerCase().indexOf('lookupphonenumber') !== -1) {
            network.url = network.url.replace(
              /phoneNumber=([^&]*)/,
              'phoneNumber=REDACTED',
            )
          }
          if (network.url.toLowerCase().indexOf('address') !== -1) {
            network.url = network.url.replace(
              /searchTerm=([^&]*)&country=([^&]*)/,
              'searchTerm=REDACTED&country=REDACTED',
            )
          }
          if (network.headers) {
            for (const key in network.headers) {
              const header = key.toLowerCase()
              if (header === 'authorization' || header === 'bearer') {
                network.headers[key] = '*'
              }
            }
          }
        }
        settings = {
          release: import.meta.env.VUE_APP_VERSION,
          shouldCaptureIP: false,
          network: {
            requestSanitizer: (request) => {
              sanitizeHeader(request)
              if (
                request.url.toLowerCase().indexOf('/resources') !== -1 ||
                request.url.toLowerCase().indexOf('/system') !== -1
              ) {
                return request
              } else if (
                request.url.toLowerCase().indexOf('/transfer/profile') !== -1
              ) {
                if (request.body !== '') {
                  let keyvalue = JSON.parse(request.body)
                  keyvalue.forEach((element) => {
                    element.value = '*'
                  })
                  request.body = JSON.stringify(keyvalue)
                }
                return request
              } else if (request.url.toLowerCase().indexOf('segment') !== -1) {
                if (request.body !== '') {
                  try {
                    let kv = JSON.parse(request.body)
                    if (kv !== 'undefined') {
                      const propArray = ['traits', 'properties']
                      propArray.forEach((tp) => {
                        let ptvalue = kv[tp]
                        if (ptvalue !== 'undefined') {
                          for (let ele in ptvalue) {
                            ptvalue = sanitizeData(ptvalue, ele)
                            kv.value = JSON.stringify(ptvalue)
                          }
                        }
                      })
                      request.body = JSON.stringify(kv)
                    }
                  } catch (exception) {
                    return request
                  }
                }
                return request
              } else {
                requestSanitizer(request)
                return request
              }
            },
            responseSanitizer: (response) => {
              sanitizeHeader(response)
              if (
                response.url.toLowerCase().indexOf('/resources') !== -1 ||
                response.url.toLowerCase().indexOf('/system') !== -1
              ) {
                return response
              } else if (
                response.url.toLowerCase().indexOf('/profiles') !== -1
              ) {
                if (response.body !== '') {
                  try {
                    let reskeyvalue = JSON.parse(response.body)
                    if (reskeyvalue !== 'undefined') {
                      const obj = [
                        'idDocDetails',
                        'freeFormatAddress',
                        'fixedFormatAddress',
                      ]
                      for (let key in reskeyvalue) {
                        try {
                          if (key !== 'undefined' && obj.includes(key)) {
                            let docvalue = reskeyvalue[key]
                            if (docvalue !== 'undefined') {
                              for (let doc in docvalue) {
                                docvalue = sanitizeData(docvalue, doc)
                              }
                              reskeyvalue[key] = JSON.stringify(docvalue)
                            }
                          } else {
                            reskeyvalue = sanitizeData(reskeyvalue, key)
                          }
                        } catch (exception) {}
                      }
                      response.body = JSON.stringify(reskeyvalue)
                    }
                  } catch (exception) {
                    return response
                  }
                }
                return response
              } else {
                responseSanitizer(response)
                return response
              }
            },
          },
          dom: {
            isEnabled: true, //disable|enable all DOM recording
            inputSanitizer: true, // obfuscate all user-input elements <select> and <input>
          },
        }
      }

      // Log Rocket
      console.log(env().VUE_APP_KEYS_LOGROCKET_KEY)
      LogRocket.init(env().VUE_APP_KEYS_LOGROCKET_KEY, settings)
    },
  },
})
