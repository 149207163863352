export const SEGMENT_ERRORS = {
  REGISTRATION_ERROR: 'Registration error',
}

export const SEGMENT_EVENTS = {
  MT_REGISTRATION_START: 'MT Registration Started',
  REGISTRATION_COUNTRY_STARTED: 'Where Do You Live Started',
  REGISTRATION_COUNTRY_SELECT: 'Registration Country Selected',
  REGISTRATION_COUNTRY_CHANGE: 'Registration Country Changed',
  REGISTRATION_MARKETING_PROMPT_DISPLAYED: 'Marketing Prompt Displayed',
  REGISTRATION_COUNTRY_NOTSUPPORTED: 'Registration Country Not Supported',
  ERROR_SOMETHING_WENT_WRONG: 'Something Went Wrong',
  PROFILE_STARTED: 'Profile Started',
  ADDRESS_UPDATED: 'Address Updated',
  PERSONAL_DETAILS_COMPLETED: 'Personal Details Completed',
  ID_DETAILS_COMPLETED: 'ID Details Completed',
  CONTINUE_TO_SIKHONA: 'Continue to Sikhona',
}

export const SEGMENT_LOCATIONS = {
  REGISTRATION: 'Registration',
  PRODUCT: 'Galileo Reg',
  VERSION: 'Personal',
}
export const SEGMENT_REFERENCE_SOURCE = 'XEMT Personal'
export const SEGMENT_PLATFORM = 'Web'
