import APIHandler, { useHeaders } from '~/api/APIHandler'
import { useRouter } from 'vue-router'
import { useEnvStore } from '~/store/env'

const { _headers, setHeader, removeHeader } = useHeaders()
const defaultErrorHandler = (error) => {
  const status = error?.response?.status
  if (status !== 401 || status !== 403) {
    useRouter().replace('/register/error')
    return null
  }
}

export default class LaunchpadAPIHandler extends APIHandler {
  constructor(
    API,
    requestHandler,
    responseHandler,
    errorHandler = defaultErrorHandler
  ) {
    super(API, requestHandler, responseHandler, errorHandler)

    // Before each request add request headers
    this.addRequestInterceptor({
      key: 'add-request-headers',
      requestHandler: (config) => {
        Object.keys(_headers).forEach((key) => {
          // Add header if not define in initial request
          if (!config.headers.common[key]) {
            config.headers.common[key] = _headers[key]
          }
        })
        return config
      },
    })

    // If any request unhandled 401 or 403 redirect user to login page
    this.addResponseInterceptor({
      key: '401-error-response',
      errorHandler: (error) => {
        const status = error?.response?.status
        //Redirect to Account-sites's /signin in case of gso token expired and launchpad-api returns 401
        if (status === 401 || status === 403) {
          const loginUrl = useEnvStore().VUE_APP_ENDPOINTS_ACCOUNT_SITE
          window.removeEventListener('beforeunload', window.handleWindowClose)
          window.onbeforeunload = null // prevent 'Changes will not be saved' popup
          window.location = loginUrl
          //return empty promises like IRL so downstream doesn't throw error while redirecting
          return Promise.resolve(() => { })
        } else {
          return Promise.reject(error)
        }
      },
    })
  }
}

export { setHeader, removeHeader }

let debounceId = 0
export const debounce = (fn, delay = 250) => {
  return new Promise((resolve) => {
    debounceId++
    if (debounceId > 100000) {
      debounceId = 0
    }
    const id = debounceId
    window.setTimeout(() => {
      if (debounceId === id) {
        fn()
        resolve(true)
      } else {
        resolve(false)
      }
    }, delay)
  })
}
