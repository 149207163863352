import API from '~/api/launchpad'
import APIHandler from '~/api/LaunchpadAPIHandler'
import Country from '~/models/PersonalRegistration/Country/app'

export default new APIHandler(
  API,
  ({ country, brandId, language }, config) => {
    return {
      ...config,
      method: 'GET',
      url: `system/fields/${country}?brandId=${brandId}&language=${language}`,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data.formData.moneyTransferCalculatorForm.formFields
        .find((element) => element.id === 'Countries')
        .options.map((item) => new Country(item, false)),
      supportedCountries:
        response.data.formData.moneyTransferCalculatorForm.formFields
          .find((element) => element.id === 'SupportedCountries')
          .options.map((item) => new Country(item, true)),
      geoCountryCode: response.data.geoLocation.countryCode,
      phoneCountries:
        response.data.formData.moneyTransferCalculatorForm.formFields.find(
          (element) => element.id === 'phoneCountries'
        ).options,
    }
  }
)
