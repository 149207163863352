<template>
  <div>
    <OenUiSpinnerBig :loading="!loaded"></OenUiSpinnerBig>
    <div v-if="loaded">
      <!-- idle handler -->
      <v-idle
        @idle="logUserOut()"
        :reminders="[inactivityWarningAt]"
        :duration="inactivityTimeout"
        @remind="inactivityWarning()"
        :events="['keypress', 'mousedown', 'touchstart']"
        class="hideDiv"
      />
      <InformationAppModal
        v-model="showInactivityWarning"
        icon="warningYellow"
        @confirm="closeInactivity"
      >
        <h1 class="title">{{ $t('ComponentRegister.IdleTitle') }}</h1>

        <p v-html="$t('ComponentRegister.IdleText', { seconds: countdown })" />

        <template #footer="{ confirm }">
          <OenUiButton @click="confirm">
            {{ $t('RegisterPersonal.continueButtonText') }}
          </OenUiButton>
        </template>
      </InformationAppModal>
      <RouterView />
  </div>
  </div>
</template>

<script lang="ts">
import { onBeforeMount, ref } from 'vue'
import Cookies from 'js-cookie'
import WebFont from 'webfontloader'
import { useHead } from '@unhead/vue'
import { useAnalyticsStore } from '~/store/analytics'
import { useConsentStore } from '~/store/consentStore'
import { useAppStore } from '~/store/appStore'
import { useAuthStore } from '~/store/authStore'
import { usePersonalRegistrationStore as prStore } from '~/store/personalRegistration'
import InformationAppModal from '~/components/InformationAppModal/InformationAppModal.vue'
import { useBrandStore } from '~/store/brand'
import { brands } from '~/constants/idConstants'
import { setPrismicTheme } from './Helpers/prismic'
import xeLogo from './assets/images/xe-logo.svg'


export default {
  components: {
    InformationAppModal,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'App',
    // all titles will be injected into this template
    titleTemplate: '%s | XE Money Transfer',
  },
  setup() {
    const consent = useConsentStore()
    const analyticsStore = useAnalyticsStore()
    const appStore = useAppStore()

    const showInactivityWarning = ref(false)
    const inactivityTimeout = ref(15 * 60) // 15 minute timeout
    const inactivityWarningAt = ref(60) // 1 minute warning
    const countdown = ref(0)
    const timer = ref(null)
    const brandStore = useBrandStore()
    const brand = brandStore.brandName

    const startCountdown = () => {
      countdown.value = inactivityWarningAt.value
      timer.value = setInterval(() => {
        countdown.value--

        //prevents scenario where countdown goes to negative when user gets active during countdown
        if (countdown.value < 1) {
          logUserOut()
        }
      }, 1000)
    }

    const stopCountdown = () => {
      clearInterval(timer.value)
      timer.value = null
    }

    const closeInactivity = () => {
      showInactivityWarning.value = false
      stopCountdown()
    }

    const inactivityWarning = () => {
      showInactivityWarning.value = true
      startCountdown()
    }

    const logUserOut = async () => {
      // Redirect to account site  logout
      showInactivityWarning.value = false
      await useAuthStore().logoutAndRedirectToTimeout()
    }
    //  Consent Manager

    // Consent model validation
    const validateConsentModel = (consent) => {
      try {
        const { performance, marketing, compliance } = consent
        return (
          typeof performance === 'boolean' &&
          typeof marketing === 'boolean' &&
          typeof compliance === 'boolean'
        )
      } catch (_e) {
        return false
      }
    }

    // Gets the consent from cookie
    const getConsentFromCookie = () => {
      const rawCookie = Cookies.get('xeConsentState')
      const consentCookie = JSON.parse(decodeURIComponent(rawCookie || '{}'))
      if (validateConsentModel(consentCookie)) {
        return consentCookie
      }
      return false
    }

    // Gets the consent from datalayer
    const getConsentFromDatalayer = () => {
      const { dataLayer } = window
      const lastConsentChange = dataLayer
        ?.filter((value) => value.event === 'Consent State Change')
        .pop()
      if (validateConsentModel(lastConsentChange)) {
        return lastConsentChange
      }
      return {
        performance: true,
        marketing: true,
        compliance: true,
      }
    }

    prStore().getLeadSourceFromQueryString()
    // Assigns the consent state according to the user location
    const consentState = window.xeSession.isGeoEurope
      ? getConsentFromCookie()
      : getConsentFromDatalayer()

    // Corporate referral id
    prStore().updateLeadSourceCookies(consentState)

    if (brand !== brands.britline) {
      // Send GTM event - 'Consent State Change'
      analyticsStore.gtmTrack({
        event: 'Consent State Change',
        variables: {
          isConsentedToPerformance: consentState.performance,
          isConsentedToMarketing: consentState.marketing,
          isConsentedToCompliance: consentState.compliance,
        },
      })
    }

    const pageHead = useHead({})

    const loaded = ref(false)
    onBeforeMount(async () => {
      await consent.setConsent(consentState)
      await analyticsStore.init()
      appStore.setVersion()
      let continueFlow = await useAuthStore().checkGso()
      if (brand === brands.xe) {
        brandStore.setBrandLogo(xeLogo)
        WebFont.load({
          google: {
            families: ['Inter:400,500,700'],
          },
        })

        pageHead?.patch({
          meta: [
            {
              name: 'msapplication-TileColor',
              content: '#0A146E',
            },
          ],
          link: [
            {
              rel: 'icon',
              href: '/favicons/favicon.ico',
            },
            {
              rel: 'apple-touch-icon',
              type: 'image/png',
              href: '/favicons/apple-touch-icon.png',
              sizes: '180x180',
            },
            {
              rel: 'icon',
              type: 'image/png',
              href: '/favicons/favicon-32x32.png',
              sizes: '32x32',
            },
            {
              rel: 'icon',
              type: 'image/png',
              href: '/favicons/favicon-16x16.png',
              sizes: '16x16',
            },
            {
              rel: 'mask-icon',
              href: '/favicons/safari-pinned-tab.svg',
              color: '#0A146E',
            },
          ],
        })
      } else {
        const theme = await setPrismicTheme(brand)

        pageHead?.patch({
          meta: [
            {
              name: 'msapplication-TileColor',
              content: theme?.primaryColor,
            },
          ],
          link: [
            {
              rel: 'icon',
              href: theme?.favicons?.['favicon'],
            },
            {
              rel: 'apple-touch-icon',
              type: 'image/png',
              href: theme?.favicons?.['apple-touch-icon'],
              sizes: '180x180',
            },
            {
              rel: 'icon',
              type: 'image/png',
              href: theme?.favicons?.['favicon-32x32'],
              sizes: '32x32',
            },
            {
              rel: 'icon',
              type: 'image/png',
              href: theme?.favicons?.['favicon-16x16'],
              sizes: '16x16',
            },
            {
              rel: 'mask-icon',
              href: theme?.favicons?.['safari-pinned-tab'],
              color: theme?.primaryColor,
            },
          ],
        })
      }
      if (continueFlow) loaded.value = true
    })

    return {
      loaded,
      showInactivityWarning,
      logUserOut,
      inactivityWarning,
      closeInactivity,
      inactivityTimeout,
      inactivityWarningAt,
      countdown,
    }
  },
}
</script>
<style scoped>
.hideDiv {
  display: none;
}
</style>
