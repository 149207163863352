import API from '~/api/launchpad'
import APIHandler from '~/api/LaunchpadAPIHandler'

export default new APIHandler(
  API,
  (phone, config) => {
    return {
      ...config,
      method: 'GET',
      url: `twofactorauth/LookupPhoneNumber?phoneNumber=${phone}`,
    }
  },
  (response) => {
    return {
      ...response,
    }
  },
  (err) => {
    throw err
  }
)
