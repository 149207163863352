import API from '~/api/launchpad'
import APIHandler from '~/api/LaunchpadAPIHandler'

export default new APIHandler(
  API,
  (
    {
      quoteId,
      profileId,
      country,
      kycFields,
      stage,
      sendMoneyForm = null,
      recipientId = null,
    },
    config
  ) => {
    let recipients = null
    if (recipientId) recipients = [{ recipientId }]

    let transferId = null
    let blackbox = null
    let quote = null
    if (quoteId) quote = { quoteId }

    if (sendMoneyForm) {
      transferId = sendMoneyForm.transferId
      blackbox = sendMoneyForm.blackbox
    }

    return {
      ...config,
      method: 'POST',
      url: 'transfer',
      data: {
        id: transferId,
        client: {
          profileId,
          country,
          blackbox,
        },
        kycInformation: kycFields,
        quote,
        recipients,
        stage,
      },
    }
  },
  async (response) => {
    let data = null
    if (response.data && response.data.data) {
      data = {
        transferId: response.data.data.id,
        kycRequirements: response.data.data.kycRequirements,
        errors: response.data.data.errors,
        verified: response.data.data.verified,
        success: response.data.statusCode === 'OK',
      }
    }

    return {
      ...response,
      data: data,
    }
  }
)
