// import { ViteSSG } from 'vite-ssg'
import { createApp } from 'vue'
import App from './App.vue'
import { useTailwindConfig } from '@oen.web.vue/ui'
import router from './router'
import config from './config/tailwind'
import vueDebounce from 'vue-debounce'
import VIdle from 'v-idle-3'
import PrimeVue from 'primevue/config'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { useEnvStore } from '~/store/env'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createHead } from '@unhead/vue'

useTailwindConfig(config as any)

import './assets/styles/index.css'
import 'primeicons/primeicons.css'
import prismic from './Helpers/prismic'
import { setI18n } from './modules/i18n'


const app = createApp(App)
const i18nObject = setI18n()


const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)
app.use(PiniaVuePlugin)
//persist pinia here

const head = createHead()
app.use(head)

// Any other way to initialise env upfront ?  This also initialises API.
useEnvStore()
  .init()
  .then(async () => {
    // If we import this earlier we get API initialisation errors
    const { usePersonalRegistrationStore: prStore } = await import(
      '~/store/personalRegistration'
    )
    const { userLocale, i18n } = await i18nObject
    prStore().setUserLocale(userLocale)

    await prStore().getCountryList('XX')
    await prStore().getSystemFields()
    prStore().setCurrencyList()

    app.use(vueDebounce, { listenTo: 'input' })
    app.use(VIdle, {})
    app.use(i18n)
    app.use(PrimeVue)
    app.use(router)
    app.use(prismic)
    app.mount('#app')
  })

