import API from '~/api/launchpad'
import APIHandler from '~/api/LaunchpadAPIHandler'

class SystemFieldsResult {
  constructor(data) {
    this.documents = data.documents

    if (data.formData) {
      if (
        data.formData.additionalForm &&
        data.formData.additionalForm.formFields
      ) {
        const occupations = data.formData.additionalForm.formFields.find(
          (fields) => fields.id === 'occupations'
        )
        if (occupations) this.occupations = occupations.options
      }
      if (
        data.formData.moneyTransferCalculatorForm &&
        data.formData.moneyTransferCalculatorForm.formFields
      ) {
        let countryMap = {}
        const countries =
          data.formData.moneyTransferCalculatorForm.formFields.find(
            (fields) => fields.id === 'Countries'
          )
        if (countries) {
          this.countries = countries.options
          this.countries.forEach((country) => {
            countryMap[country.value] = country.text
          })
        }
        const phoneCountries =
          data.formData.moneyTransferCalculatorForm.formFields.find(
            (fields) => fields.id === 'phoneCountries'
          )
        if (phoneCountries)
          this.phoneCountries = phoneCountries.options.map((country) => {
            // for compatibility reasons with RMT4 swap it
            return {
              text: '+' + country.text + ' - ' + countryMap[country.value],
              value: country.text,
              valueKey: country.value,
              nameAbbr: country.value,
            }
          })

        const supportedCountries =
          data.formData.moneyTransferCalculatorForm.formFields.find(
            (fields) => fields.id === 'SupportedCountries'
          )
        if (supportedCountries)
          this.supportedCountries = supportedCountries.options

        const currencies =
          data.formData.moneyTransferCalculatorForm.formFields.find(
            (fields) => fields.id === 'currencies'
          )
        this.currenciesFrom = []
        this.currenciesTo = []
        if (currencies && currencies.options)
          this.currencies = currencies.options.map((currency) => {
            let enableFrom = false
            let enableTo = false
            try {
              const currencyData = JSON.parse(currency.jsonContent)
              if (currencyData) {
                enableFrom = currencyData.CanSell
                enableTo = currencyData.CanBuy
              }
            } catch (ex) {}

            const data = {
              text: currency.text,
              value: currency.value,
              enableFrom,
              enableTo,
            }
            if (enableFrom) this.currenciesFrom.push(data)
            if (enableTo) this.currenciesTo.push(data)
            return data
          })
        const topCountries =
          data.formData.moneyTransferCalculatorForm.formFields.find(
            (fields) => fields.id === 'topCountries'
          )
        if (topCountries) this.topCountries = topCountries.options
        const topCurrencies =
          data.formData.moneyTransferCalculatorForm.formFields.find(
            (fields) => fields.id === 'topCurrencies'
          )
        if (topCurrencies) this.topCurrencies = topCurrencies.options
      }
      if (
        data.formData.purposeOfPaymentForm &&
        data.formData.purposeOfPaymentForm.formFields
      ) {
        const purposeOfPaymentPrivateDefault =
          data.formData.purposeOfPaymentForm.formFields.find(
            (fields) => fields.id === 'purposeOfPayment-Private-Default'
          )
        if (purposeOfPaymentPrivateDefault)
          this.purposeOfPaymentPrivateDefault =
            purposeOfPaymentPrivateDefault.options
        const purposeOfPaymentPrivateInr =
          data.formData.purposeOfPaymentForm.formFields.find(
            (fields) => fields.id === 'purposeOfPayment-Private-Inr'
          )
        if (purposeOfPaymentPrivateInr)
          this.purposeOfPaymentPrivateInr = purposeOfPaymentPrivateInr.options
        const purposeOfPaymentCorporateDefault =
          data.formData.purposeOfPaymentForm.formFields.find(
            (fields) => fields.id === 'purposeOfPayment-Corporate-Default'
          )
        if (purposeOfPaymentCorporateDefault)
          this.purposeOfPaymentCorporateDefault =
            purposeOfPaymentCorporateDefault.optons
        const purposeOfPaymentCorporateInr =
          data.formData.purposeOfPaymentForm.formFields.find(
            (fields) => fields.id === 'purposeOfPayment-Corporate-Inr'
          )
        if (this.purposeOfPaymentCorporateInr)
          this.purposeOfPaymentCorporateInr =
            purposeOfPaymentCorporateInr.options
      }
      if (
        data.formData.sourceOfFundsForm &&
        data.formData.sourceOfFundsForm.formFields
      ) {
        const sourceOfFunds = data.formData.sourceOfFundsForm.formFields.find(
          (fields) => fields.id === 'sourceoffunds'
        )
        if (sourceOfFunds) this.sourceOfFunds = sourceOfFunds.options
      }
    }

    this.onlineCredentialId = data.onlineCredentialId
    this.profileId = data.profileId
    this.region = data.region
  }
}

export default new APIHandler(
  API,
  ({ country, brandId, language }, config) => {
    return {
      ...config,
      method: 'GET',
      url: `system/fields/${country}?brandId=${brandId}&language=${language}`,
    }
  },
  (response) => {
    return {
      ...response,
      data: new SystemFieldsResult(response.data),
    }
  }
)
