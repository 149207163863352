import getGsoToken from '~/api/personal/authorize/gsoToken/get'
import createProfile from '~/api/launchpad/profiles/post'
import patchProfile from '~/api/launchpad/profiles/_profileId/patch'
import { setHeader, removeHeader } from '~/api/APIHandler'
import { jwtDecode } from 'jwt-decode'
import LogRocket from 'logrocket'
import validatePhone from '~/api/launchpad/twilio/get'
import Cookies from 'js-cookie'
import { defineStore } from 'pinia'
import { useEnvStore } from '~/store/env'
import { usePersonalRegistrationStore as prStore } from '~/store/personalRegistration'
import { useAppStore } from '~/store/appStore'
import { useConsentStore } from './consentStore'

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      token: null,
      lastLogin: null,
      profileId: null,
      userProfile: {},
      loginUrl: null,
      tokenUrl: null,
      refreshToken: null,
      session: null,
    }
  },
  getters: {},
  actions: {
    resetState: (state) => {
      const keys = Object.keys(defaultState)
      for (const key of keys) {
        if (key !== 'lastLogin') {
          state[key] = defaultState[key]
        }
      }
    },

    async checkGso() {
      this.loginUrl = useEnvStore().VUE_APP_URL_LOGIN
      this.tokenUrl = useEnvStore().VUE_APP_URL_TOKEN
      let url = this.loginUrl
      try {
        if (window.location.search) {
          url = url + '&state=' + encodeURIComponent(window.location.search)
        }
        const { data } = await getGsoToken.exec(this.tokenUrl)

        // We have got a token so can remove the redirect helper cookie
        Cookies.remove('xe_redirect_helper')

        if (data && data.accessToken) {
          // Saves the token on the request
          this.token = data.accessToken
          this.refreshToken = data.refreshToken
          setHeader('Authorization', `Bearer ${data.accessToken}`)

          //This is to identify users has come to signup with MoneyTransfer account, but successfully got the GsoToken
          //without 2FA - so redirecting them to Fxweb.
          const decoded = jwtDecode(data.accessToken)
          if (decoded?.profile?.clientId) {
            window.location.href = useEnvStore().VUE_APP_URL_TRANSFER
            return
          }

          // Grab XERA profile details if available. Might have restarted signup.
          // If there's already an XEMT profile then that would have been handled earlier on.
          if (decoded?.profile?.profileId) {
            this.profileId = decoded.profile.profileId
            this.userProfile = decoded.profile
          } else {
            this.profileId = null
            this.userProfile = {}
          }

          if (decoded && decoded.email) {
            if (this.lastLogin !== decoded.email) {
              this.lastLogin = decoded.email
              prStore().resetForm()
            }
          } else {
            this.lastLogin = null
            this.profileId = null
          }

          if (decoded.profile) {
            try {
              LogRocket.identify(decoded.profile.profileId, {
                email: decoded.email,
              })
            } catch (error) {
              useAppStore().logException(
                'Unable to identify user in LogRocket',
                error
              )
            }
          }
        }
      } catch (ex) {
        // We have caught a 401 error. This will always happen initially as a new user comes direct to this site
        // via xe.com, so we redirect to account-ui/signup, the user signs up and back they come to personal-reg
        // where we get a GSO token and carry on.
        //
        // But what if they're an XEMT user who comes to our site via xe.com->signup ? We get a 401, redirect to
        // account-ui/signup, redirect to account-ui/login, redirect to personal-reg where we again fail to
        // get a GSO token (no 2FA) - here we want to redirect to fxweb.  We spot this by keeping a session cookie
        // to store if we've redirected away from personal reg already - if we have already redirected then
        // now redirect to fxweb.
        const xe_redirect_helper = Cookies.get('xe_redirect_helper')
        if (!xe_redirect_helper) {
          // No expiry so will automatically be a session cookie.
          Cookies.set('xe_redirect_helper', 'true')
        }
        const statusCode = ex.response.status
        useAppStore().logException('Exception during authentication', ex)

        // We have already redirected from this browser so redirect to fxweb - it must mean we
        // have failed to do 2FA
        if (
          statusCode === 401 &&
          xe_redirect_helper &&
          import.meta.env.NODE_ENV !== 'development'
        ) {
          Cookies.remove('xe_redirect_helper')
          window.location.href = useEnvStore().VUE_APP_URL_TRANSFER
          return false
        }

        // Normal case - user needs to signup (VUE_APP_URL_LOGIN points to accounts-ui/signup)
        window.location.href = url
        return false
      }
      return true
    },
    async createXeraProfile({ country, email }) {
      // if profile is already created then just 'patch' any country.
      const profileId = this.profileId
      let resp
      if (profileId !== undefined && profileId !== null) {
        resp = await patchProfile.exec({ profileId, data: { country } })
      } else {
        resp = await createProfile.exec({ country: country, email: email })
      }
      const { profileData } = resp

      if (resp.data) {
        useConsentStore().promptForEmailMarketingConsent =
          resp.data.promptForMarketingConsent ?? false
      }
      if (profileData && profileData.profileId) {
        //re-fetch gsotoken with profile claim
        const { data } = await getGsoToken.exec(useEnvStore().VUE_APP_URL_TOKEN)
        const decoded = jwtDecode(data.accessToken)
        if (decoded && decoded.profile) {
          this.token = data.accessToken
          this.refreshToken = data.refreshToken
          setHeader('Authorization', `Bearer ${data.accessToken}`)
          this.profileId = profileData.profileId
          this.userProfile = profileData
        }
      }
    },
    async validatePhone({ phone }) {
      await validatePhone.exec(phone.slice(1))
    },

    async logoutFromLaunchpad() {
      const accountSiteUrl = useEnvStore().VUE_APP_ENDPOINTS_ACCOUNT_SITE
      try {
        const launchpadLogoutUrl =
          useEnvStore().VUE_APP_ENDPOINTS_LAUNCHPADAPI + 'logout'
        await fetch(launchpadLogoutUrl, {
          credentials: 'include',
          mode: 'no-cors',
        })
        window.location.href = accountSiteUrl
      } catch (error) {
        useAppStore().logException({
          text: 'Unable to logout from Launchpad',
        })
        //Redirect to Account-Site's /signin if unable to logout
        const accountSiteUrl = useEnvStore().VUE_APP_ENDPOINTS_ACCOUNT_SITE
        window.location.href = accountSiteUrl
      }
    },

    reset() {
      // Remove Axios instance Auth Header
      removeHeader('Authorization')
      // Reset store state
      this.resetState()
    },
    async logoutAndRedirectToTimeout() {
      window.removeEventListener('beforeunload', window.handleWindowClose)
      window.onbeforeunload = null // prevent 'Changes will not be saved' popup
      try {
        await this.logoutFromLaunchpad()
      } catch (error) {
        //Redirect to Account-Site's /signin if unable to logout
        const accountSiteUrl = useEnvStore().VUE_APP_ENDPOINTS_ACCOUNT_SITE
        window.location.href = accountSiteUrl
      }
    },
  },
  persist: {
    id: 'auth',
    storage: sessionStorage,
  },
})
