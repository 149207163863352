import { BrandName } from '~/types'
import {
  brands,
  brandHostNames,
  brandDisplayNames,
} from '~/constants/idConstants'

type Config = {
  [x: string]: string
}
export function getBrandName(config: Config): BrandName {
  const hostName = window.location.hostname
  if (hostName.includes(brandHostNames.britline)) {
    return brands.britline
  } else if (hostName.includes(brandHostNames.jlp)) {
    return brands.jlp
  } else if (hostName.includes(brandHostNames.xe)) {
    return brands.xe
  } else {
    const brand = config['APP_BRAND'] ?? brands.xe
    return brand as BrandName
  }
}

export function getBrandDisplayName(brand: BrandName) {
  switch (brand) {
    case brands.xe:
      return brandDisplayNames.xe
    case brands.britline:
      return brandDisplayNames.britline
    case brands.johnLewis:
      return brandDisplayNames.jlp
  }
}

export function getBrandTitle(brand: BrandName) {
  return `${getBrandDisplayName(brand)} - `
}
