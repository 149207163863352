// Pinia Store
import { defineStore } from 'pinia'
import { useEnvStore as env } from '~/store/env'
import { useDeviceStore } from '~/store/deviceStore'
import countries from '~/api/launchpad/countries/get'
import getStates from '~/api/launchpad/countries/states/get'
import addressSearch from '~/api/launchpad/addressSearch/get'
import addressDetails from '~/api/launchpad/addressDetails/get'
import transfer from '~/api/launchpad/transfer/post'
import putProfile from '~/api/launchpad/transfer/profile/put'
import patchProfile from '~/api/launchpad/profiles/_profileId/patch'
import getSystemFields from '~/api/launchpad/system/fields/_country/get'
import acceptTermsandConditions from '~/api/launchpad/profiles/_profileId/termsConditions/put'
import Cookies from 'js-cookie'
import { useAppStore as appStore } from '~/store/appStore'
import { useAuthStore } from '~/store/authStore'
import { useConsentStore } from '~/store/consentStore'
import { useBrandStore } from '~/store/brand'
import { useI18n } from 'vue-i18n'
import { DEFAULT_LOCALE } from "~/constants/locales";


function getSteps () {
  const { t: $t } = useI18n()
  return [
    {
      title: $t('ComponentRiaSidebar.ButtonAccount'),
      path: '/',
      pageTitle: $t('createAccountPage.title'),
    },
    {
      title: $t('PageSendMoney.StepVerificationPageTitle'),
      path: '/',
      pageTitle: $t('PageSendMoney.StepVerificationTitle'),
    },
    {
      title: $t('PageRegister.StepCountryTitle'),
      path: '/country-info',
      pageTitle: $t('PageRegister.StepCountryTitle'),
    },
    {
      title: $t('PageRegister.StepInfoTitle'),
      path: '/personal-info',
      pageTitle: $t('ComponentPersonalInfoRegistration.Title'),
    },
  ]
}

export const usePersonalRegistrationStore = defineStore(
  'personalRegistration',
  {
    state: () => ({
      steps: [],
      activeStepIdx: 0,
      userGeoCountry: 'GB',
      countryList: [],
      stateList: [],
      supportCountryList: [],
      personalAddress: [],
      personalInfo: {
        firstName: '',
        middleName: '',
        lastName: '',
        dateOfBirth: '',
        phoneNumber: '',
      },
      transferNeeds: {
        amount: '',
        currency: '',
        payoutCurrency: '',
      },
      validationRules: {},
      phoneCountryList: [],
      phoneCountry: '',
      systemFields: {},
      currencies: [],
      termsAndConditionsCheckMark: false,
      idDocs: [],
      dfxId: null,
      clientAgentId: null,
      duplicateEmail: '',
      errorMsg: '',
      userLocale: DEFAULT_LOCALE
    }),
    getters: {
      activeStep (state) {
        return state.steps[state.activeStepIdx]
      },
      activeStepTitle (state) {
        return state.steps[state.steps[state.activeStepIdx].pageTitle]
      },
      getStepByPath: (state) => (path) => {
        return state.steps.find((step) => {
          return step.path === path
        })
      },
      getStepIdx: (state) => (step) => {
        return state.steps.indexOf(step)
      },
      getIfCountrySupported: (state) => (countryCode) => {
        return state.supportCountryList.some(
          (element) => element.code === countryCode
        )
      },
      getPhoneCountryList: (state) => {
        if (state.systemFields && state.systemFields.phoneCountries) {
          return state.systemFields.phoneCountries
        }
        return []
      },
      getTermsAndConditionsUrl: (state) => {
        if (
          state.systemFields &&
          state.systemFields.documents &&
          state.systemFields.documents.documentLinks
        ) {
          const version =
            state.systemFields.documents.termsAndConditionsVersionAvailable
          if (state.systemFields.documents.documentLinks[version]) {
            return state.systemFields.documents.documentLinks[version]
          }
        }
        return ''
      },
      getPrivacyPolicyUrl: (state) => {
        if (
          state.systemFields &&
          state.systemFields.documents &&
          state.systemFields.documents.documentLinks
        ) {
          const version =
            state.systemFields.documents.privacyPolicyVersionAvailable
          if (state.systemFields.documents.documentLinks[version]) {
            return state.systemFields.documents.documentLinks[version]
          }
        }
        return ''
      },
      getCurrencyList: (state) => {
        return state.currencies
      },
      getUserLocale: (state) => state.userLocale,
      getUserPreferredLanguage: (state) => state.userLocale.split('-')[0]
    },
    actions: {
      setCurrencyList () {
        this.currencies = this.systemFields.currencies.map((c) => ({
          text: c.value + ' - ' + c.text,
          value: c.value,
        }))
      },
      setSteps () {
        this.steps = getSteps()
      },
      setActiveStep (path) {
        const step = this.getStepByPath(path)
        const idx = this.getStepIdx(step)
        this.activeStepIdx = idx
      },
      setActiveStepPageTitle (pageTitle) {
        if (this.activeStepIdx && this.steps[this.activeStepIdx])
          this.steps[this.activeStepIdx].pageTitle = pageTitle
      },
      async getValidationRules (request) {
        const { data } = await transfer.exec({
          country: request.country,
          kycFields: request.kycInformation,
          stage: request.stage,
        })
        this.validationRules = data
      },

      async getCountryList (country = 'XX') {
        // Function to sort countries by ascending order
        const sortCountries = (countries) => {
          const sortedCountries = countries.sort((a, b) => {
            if (a.name > b.name) return 1
            else if (a.name < b.name) return -1
            return 0
          })
          return sortedCountries
        }
        try {
          const { data, geoCountryCode, phoneCountries, supportedCountries } =
            await countries.exec({
              country: country,
              brandId: useBrandStore().brandId,
              language: this.getUserPreferredLanguage
            })
          useDeviceStore().setCountry(geoCountryCode)
          if (data) {
            // Want 'supportedCountries' to be at the top of the list.
            // So filter main list ('data') to countries not in the supported list
            const sortedSupportedCountries = sortCountries(supportedCountries)
            const sortedUnsupportedCountries = sortCountries(
              data.filter(
                (c) =>
                  sortedSupportedCountries.findIndex(
                    (s) => s.name === c.name
                  ) == -1
              )
            )

            // Mark last supported country for the dropdown list ...
            sortedSupportedCountries.slice(-1)[0].separator = true
            const sortedCountries = sortedSupportedCountries.concat(
              sortedUnsupportedCountries
            )

            this.countryList = sortedCountries
            this.supportCountryList = sortedSupportedCountries
            this.userGeoCountry = geoCountryCode
            this.phoneCountryList = phoneCountries
          }
        } catch (exception) {
          const errorMessage =
            'Exception during getting list of countries. Error location: /personalRegistration/getCountryList'
          appStore().logException({ text: errorMessage, exception })
        }
      },

      async getStateList (country) {
        const { data } = await getStates.exec(country)
        if (data) {
          this.stateList = data
        }
      },
      async getAddressSearchResult ({ searchTerm, country, searchContext }) {
        const { data } = await addressSearch.exec({
          searchTerm,
          country,
          searchContext,
        })
        if (data) {
          return data
        }
      },
      async getAddressDetails ({ addressId }) {
        const { data } = await addressDetails.exec({ addressId })
        if (data) return data
      },
      async getSystemFields () {
        const geolocatedCountry = useDeviceStore().country
        const geoLocatedCountrySupported =
          this.getIfCountrySupported(geolocatedCountry)

        //if geo-located country is not supported, GB's legal docs are used
        const country = geoLocatedCountrySupported ? geolocatedCountry : 'GB'
        const { data } = await getSystemFields.exec({
          country: country,
          brandId: useBrandStore().brandId,
          language: this.getUserPreferredLanguage
        })
        this.systemFields = data
        return data
      },
      redirectToTransfer () {
        let transferUrl = env().VUE_APP_URL_TRANSFER
        window.location = transferUrl
      },
      async acceptTermsandConditions () {
        await this.getSystemFields()
        const data = this.systemFields
        await acceptTermsandConditions.exec({
          profileId: useAuthStore().profileId,
          versionAccepted: data.documents.termsAndConditionsVersionAvailable,
        })
      },
      mapStateValue (stateName) {
        const states = this.stateList
        if (stateName && states && states.length) {
          stateName = stateName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          const state = states.find((item) => item.name == stateName)
          if (state) return state.value
        }
        return stateName
      },
      resetForm () {
        this.personalAddress = []
        this.personalInfo.firstName = ''
        this.personalInfo.middleName = ''
        this.personalInfo.lastName = ''
        this.personalInfo.dateOfBirth = ''
        this.personalInfo.phoneNumber = ''
        this.personalInfo.mobilePhone = ''
        this.personalInfo.mobilePrefix = ''
        this.userId = ''
        this.email = ''
        this.validationRules = {
          kycRequirements: [],
        }
        this.transferNeeds = {
          amount: '',
          currency: '',
          payoutCurrency: '',
        }
        this.idDocs = []
        this.phoneCountry = ''
        this.phoneCountryList = []
        this.termsAndConditionsCheckMark = false
        useConsentStore().resetConsentStore()
        useDeviceStore().country = ''
        this.steps = []
        this.duplicateEmail = ''
        this.errorMsg = ''
      },
      async putProfile (request) {
        const { data } = await putProfile.exec(request)
        return data
      },
      async patchProfile ({ profileId, request }) {
        const { data } = await patchProfile.exec({ profileId, data: request })
        return data
      },
      getLeadSourceFromQueryString () {
        // Parses queryParams
        let queryParams = new URLSearchParams(window.location.search)

        // Redirects through Accounts UI will persist lead source params here
        let stateParam = queryParams.get('state')
        if (stateParam) {
          queryParams = new URLSearchParams(decodeURIComponent(stateParam))
        }

        const dfxId = getParamValue('_dfxid', /^\d{1,10}$/)
        this.dfxId = dfxId
        const clientAgentId = getParamValue('_clientagentid', /^[0-9|A-z]{14}$/)
        this.clientAgentId = clientAgentId

        function getParamValue (name, regex) {
          const paramValue = queryParams.get(name)
          if (regex.test(paramValue)) return paramValue
        }
      },
      updateLeadSourceCookies (consentState) {
        if (!consentState.marketing) return
        const dfxId = this.dfxId
        saveValueInCookie(dfxId, 'HiFX_DfxId', '_dfxid')
        this.dfxId = null

        const clientAgentId = this.clientAgentId
        saveValueInCookie(
          clientAgentId,
          'HiFX_ClientAffiliate',
          '_clientagentid'
        )
        this.clientAgentId = null

        function saveValueInCookie (value, cookieName, paramName) {
          if (value == null) return
          Cookies.set(cookieName, `${paramName}=${value}`, { expires: 28 })
        }
      },
      setUserLocale (locale) {
        if (locale) {
          this.userLocale = locale
        }
      }
    },
  }
)
