import { acceptHMRUpdate, defineStore } from 'pinia'
import { reactive, computed } from 'vue'
import { BrandName, BrandId } from '~/types'
import {
  getBrandDisplayName,
  getBrandName,
  getBrandTitle,
} from '~/utils/brandHelper'
import { brands, brandIds } from '~/constants/idConstants'

type Brand = {
  name: BrandName
  id: BrandId
  logo: string
}
export const useBrandStore = defineStore('brand', () => {
  const brand = reactive<Brand>({
    name: 'xe',
    id: 8,
    logo: '',
  })

  //getters
  const brandName = computed(() => brand.name)
  const brandId = computed(() => brand.id)
  const brandLogo = computed(() => brand.logo)
  const brandDisplayName = computed(() => getBrandDisplayName(brand.name))
  const brandTitle = computed(() => getBrandTitle(brand.name))

  //Actions
  const getBrandFromDomain = (config: { [x: string]: string }) => {
    const brandName = getBrandName(config)
    brand.name = brandName
  }
  const getBrandIdFromBrand = (brandName: string) => {
    if (brandName.includes(brands.britline)) {
      brand.id = brandIds.britline
    } else if (brandName.includes(brands.johnLewis)) {
      brand.id = brandIds.jlp
    } else if (brandName.includes(brands.xe)) {
      brand.id = brandIds.xe
    } else {
      brand.id = brandIds.xe
    }
    return brand.id
  }

  const setBrandLogo = (logoUrl: string) => {
    brand.logo = logoUrl
  }

  return {
    getBrandFromDomain,
    setBrandLogo,
    brandName,
    getBrandIdFromBrand,
    brandId,
    brandLogo,
    brandDisplayName,
    brandTitle,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBrandStore, import.meta.hot))
}
