<template>
<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z" fill="url(#paint0_linear_5871_7632)"/>
<path d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z" fill="url(#paint1_radial_5871_7632)"/>
<path d="M32.6667 16.3333H9.33333C8.04467 16.3333 7 17.378 7 18.6667V23.3333C7 24.622 8.04467 25.6667 9.33333 25.6667H32.6667C33.9553 25.6667 35 24.622 35 23.3333V18.6667C35 17.378 33.9553 16.3333 32.6667 16.3333Z" fill="url(#paint2_linear_5871_7632)"/>
<defs>
<linearGradient id="paint0_linear_5871_7632" x1="21" y1="0" x2="21" y2="42" gradientUnits="userSpaceOnUse">
<stop stop-color="#FF8282"/>
<stop offset="1" stop-color="#D61313"/>
</linearGradient>
<radialGradient id="paint1_radial_5871_7632" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(21 21) scale(21)">
<stop stop-color="#FF7575" stop-opacity="0"/>
<stop offset="0.406" stop-color="#FE7474" stop-opacity="0.011"/>
<stop offset="0.553" stop-color="#FA6F6F" stop-opacity="0.052"/>
<stop offset="0.657" stop-color="#F46767" stop-opacity="0.123"/>
<stop offset="0.741" stop-color="#EB5C5C" stop-opacity="0.225"/>
<stop offset="0.813" stop-color="#DF4D4D" stop-opacity="0.358"/>
<stop offset="0.877" stop-color="#D13B3B" stop-opacity="0.522"/>
<stop offset="0.935" stop-color="#BF2525" stop-opacity="0.717"/>
<stop offset="0.987" stop-color="#AC0C0C" stop-opacity="0.936"/>
<stop offset="1" stop-color="#A60505"/>
</radialGradient>
<linearGradient id="paint2_linear_5871_7632" x1="21" y1="16.3333" x2="21" y2="25.6667" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="0.325" stop-color="#FBFBFC"/>
<stop offset="0.662" stop-color="#EEEEF4"/>
<stop offset="1" stop-color="#DADAE6"/>
</linearGradient>
</defs>
</svg>
</template>
