import { createPrismic } from '@prismicio/vue'
import WebFont from 'webfontloader'
import { useBrandStore } from '~/store/brand'

const prismic = createPrismic({
  endpoint: 'https://rmt4-secure.cdn.prismic.io/api/v2',
})

export default prismic

export const setPrismicTheme = async (brand: string) => {
  try {
    const theme = await prismic.client.getByUID('galileo', brand)

    const { variables, favicons } = parsePrismicData(theme.data)

    const styles = Object.keys(variables).map((x) => `${x}:${variables[x]};`)

    const fontFamily = variables['--theme-font-family'] || 'Inter'

    WebFont.load({
      google: {
        families: [`${fontFamily}:400,500,700&display=swap`],
      },
    })

    // generate stylesheet css from styles array
    const css = `:root { ${styles.join('')} }`

    // Create stylesheet html element
    let style = document.getElementById('theme-styles')
    if (style) {
      style.innerHTML = ''
    } else {
      style = document.createElement('style')
      style.id = 'theme-styles'
    }

    style.appendChild(document.createTextNode(css))

    const head = document.head || document.getElementsByTagName('head')[0]
    head.appendChild(style)

    const wrapper = document.getElementById('wrapper')

    if (wrapper) {
      wrapper.className = 'branded'
    }

    const primaryColor = theme.data['variables--color-primary'][0].hex

    return { variables, favicons, primaryColor }
  } catch (err) {
    console.log(`Unable to fetch theme for brand ${brand}`, err)
  }
}

const parsePrismicData = (data: Record<string, any>) => {
  const entries = Object.entries(data)

  useBrandStore().setBrandLogo(
    entries.find((x) => x[0] === 'variables--logo')?.[1][0].image.url
  )

  const cssVariables = getVariables(entries)

  const favicons = getFavicons(entries)

  return {
    variables: cssVariables,
    favicons: favicons as Record<string, string>,
  }
}

const getVariables = (entries: [string, any][]) => {
  const variableEntries = entries.filter(
    (x) => x[0].split('--')[0] === 'variables'
  )

  let colorEntries = variableEntries.filter((x) => x[1]?.[0]?.hex)

  colorEntries = colorEntries.map((x) => {
    let varName = x[0]
    varName = varName.replace('variables-', '--theme')
    const color = parseRgba(x[1][0].hex, x[1][0].alpha)

    return [varName, color]
  })

  let stringEntries = variableEntries.filter((x) => typeof x[1] === 'string')

  stringEntries = stringEntries.map((x) => {
    let varName = x[0]
    varName = varName.replace('variables-', '--theme')
    return [varName, x[1]]
  })

  const logoEntry = entries.find((x) => x[0] === 'variables--logo')?.[1]?.[0]

  const logoEntries = [
    ['--theme-logo-width', logoEntry?.['max-width']],
    ['--theme-logo-height', logoEntry?.['max-height']],
  ]

  return Object.fromEntries([...colorEntries, ...stringEntries, ...logoEntries])
}

const getFavicons = (entries: [string, any][]) => {
  let faviconEntries = Object.entries(
    entries.filter((x) => x[0].split('--')[1] === 'favicons')[0][1][0]
  )
  faviconEntries = faviconEntries.map((x: [string, any]) => {
    const varName = x[0]
    const iconUrl = x[1].url

    return [varName, iconUrl]
  })

  return Object.fromEntries(faviconEntries)
}

const parseRgba = (hex: string, alpha: string | null) => {
  const rgbValues = hexToRGBValues(hex)
  if (rgbValues) {
    return alpha
      ? `${rgbValues.red} ${rgbValues.green} ${rgbValues.blue}/${alpha}`
      : `${rgbValues.red} ${rgbValues.green} ${rgbValues.blue}`
  }

  return false
}

const hexToRGBValues = (hex: string) => {
  if (hex.length === 7 && hex.slice(0, 1) === '#') {
    return {
      red: parseInt(hex.slice(1, 3), 16),
      green: parseInt(hex.slice(3, 5), 16),
      blue: parseInt(hex.slice(5, 7), 16),
    }
  } else if (hex.length === 4 && hex.slice(0, 1) === '#') {
    return {
      red: parseInt(`${hex.slice(1, 2)}${hex.slice(1, 2)}`, 16),
      green: parseInt(`${hex.slice(1, 2)}${hex.slice(1, 2)}`, 16),
      blue: parseInt(`${hex.slice(5, 7)}${hex.slice(5, 7)}`, 16),
    }
  }
  return false
}
