export const IdTypes = Object.freeze({
  LICENCE: 'DriverLicence',
  PASSPORT: 'Passport',
})

export const FieldTypes = Object.freeze({
  DATE: 'Date',
  RANGEDATE: 'RangeDate',
  STRING: 'String',
  LABEL: 'Label',
  PLACEHOLDERTEXT: 'PlaceholderText',
  TEXT: 'Text',
  HINT: 'HintText',
  OPTIONAL: 'OptionalText',
  NUMBER: 'Number',
  SELECTION: 'Selection',
  COUNTRYLIST: 'CountryList',
  FIELDGROUP: 'FieldGroup',
})

export const VerifyTypes = Object.freeze({
  LICENCE: 'DriverLicence',
  PASSPORT: 'Passport',
  LICENCE_VERSION: 'licenceVersion',
  LICENCE_NUMBER: 'licenceNumber',
  LICENCE_ISSUER: 'licenceIssuer',
  LICENCE_CARD_NUMBER: 'licenceCardNumber',
  LICENCE_EXPIRY_DATE: 'licenceExpiryDate',
  ID_EXPIRY_DATE: 'idExpiryDate',
  PASSPORT_EXPIRY_DATE: 'passportExpiryDate',
  PASSPORT_NUMBER: 'passportNumber',
  PASSPORT_ISSUER: 'passportIssuer',
  PASSPORT_FILE_NUMBER: 'passportFileNumber'
})

export const brands = Object.freeze({
  xe: 'xe',
  jlp: 'jlp',
  britline: 'britline',
})

export const brandHostNames = Object.freeze({
  xe: 'xe',
  jlp: 'jlp',
  britline: 'cabips',
})

export const platforms = Object.freeze({
  XEMT: 'XEMT',
  XECI: 'XECI',
  XERA: 'XERA',
  XECD: 'XECD',
  BRIT: 'BRIT',
  JLFX: 'JLFX',
})

export const brandIds = Object.freeze({
  xe: 8,
  jlp: 6,
  britline: 7,
})

export const brandDisplayNames = Object.freeze({
  xe: 'Xe',
  jlp: 'JLFX',
  britline: 'BIPS',
})
