import { createRouter, createWebHistory } from 'vue-router'
import { values, isEmpty } from 'lodash-es'
import { usePersonalRegistrationStore as prStore } from '~/store/personalRegistration'
// Guards
import { useAppOverlayRouterGuards } from '~/composables/useAppOverlay'

export const routes = [
  /*
  =====================================================================================================
  XE personal registration
  =====================================================================================================
  */
  {
    path: '',
    name: 'home',
    redirect: () => {
      return { name: 'RegisterCountrySelection' }
    },
  },
  {
    path: '/register',
    name: 'register',
    redirect: () => {
      return { name: 'RegisterCountrySelection' }
    },
    component: () => import('~/views/layout/Register.vue'),
    children: [
      {
        path: 'country-info',
        name: 'RegisterCountrySelection',
        component: () =>
          import('~/views/personal/RegisterCountrySelection.vue'),
      },
      {
        path: 'personal-info',
        name: 'RegisterPersonalInfo',
        component: () => import('~/views/personal/RegisterPersonalInfo.vue'),
      },
      {
        path: 'personal-address/search',
        name: 'RegisterPersonalAddressSearch',
        component: () => import('~/views/personal/RegisterAddressSearch.vue'),
        props: {
          searchType: 'personal',
        },
      },
      {
        path: 'personal-address',
        name: 'PersonalAddress',
        component: () => import('~/views/personal/RegisterPersonalAddress.vue'),
      },
      {
        path: 'personal-identity',
        name: 'RegisterPersonalIdentity',
        component: () =>
          import('~/views/personal/RegisterPersonalIdentity.vue'),
      },
      {
        path: 'transfer-needs',
        name: 'RegisterTransferNeeds',
        component: () => import('~/views/personal/RegisterTransferNeeds.vue'),
      },
      {
        path: 'submit',
        name: 'RegisterSubmit',
        component: () => import('~/views/personal/RegisterSubmit.vue'),
      },
    ],
  },
  {
    path: '/register/error',
    name: 'error',
    props: true,
    beforeEnter (to, from, next) {
      history.pushState({}, '', from.fullPath)
      next()
    },
    component: () => import('~/components/XeError/XeError.vue'),
  },
  {
    path: '/register/duplicate',
    name: 'duplicate',
    props: true,
    component: () => import('~/components/XeDuplicate/XeDuplicate.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('~/views/PageNotFound.vue'),
    meta: {
      auth: false,
    },
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { left: 0, top: 0 }
  },
})
router.beforeEach((to, from) => {
  //check if landed directly from '/' (cases like page refreshed)
  if (from.path === '/' && to.path !== '/register/country-info') {
    return { name: 'RegisterCountrySelection' }
  }
})

useAppOverlayRouterGuards(router)

export default router
