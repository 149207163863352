import putEmailMarketingPreference from '~/api/personal/marketingPreferenceConsent/putEmailMarketingPreference'

import { defineStore } from 'pinia'

export const useConsentStore = defineStore('consent', {
  state: () => {
    return {
      consentState: false,
      promptForEmailMarketingConsent: false,
      profileId: null,
      changedBy: null,
      isDirty: false, // Track if consent already updated
      consentedToEmailMarketing: false,
    }
  },
  getters: {
    getConsentState(state) {
      return state.consentState
    },
    getEmailMarketingConsent(state) {
      return state.promptForEmailMarketingConsent
    },
  },
  actions: {
    async setConsent(consent) {
      this.consentState = consent
    },
    async updateEmailMarketingPreferences(consentedToEmailMarketing) {
      //No need to update same values over and over
      if (
        this.isDirty &&
        consentedToEmailMarketing === this.consentedToEmailMarketing
      ) {
        return
      }
      try {
        const { statusCode } = await putEmailMarketingPreference.exec({
          profileId: this.profileId,
          promptForMarketingConsent: false,
          email: consentedToEmailMarketing,
          changedBy: this.changedBy,
        })

        if (statusCode === 200) {
          this.promptForEmailMarketingConsent = false
          this.consentedToEmailMarketing = consentedToEmailMarketing
          this.isDirty = true
        }
      } catch (ex) {
        console.error('Exception during updating marketing preferences')
        console.error(ex)
      }
    },
    resetConsentStore() {
      this.promptForEmailMarketingConsent = false
      this.isDirty = false
      this.consentedToEmailMarketing = false
    },
  },
  async updateEmailMarketingPreferences(consentedToEmailMarketing) {
    //No need to update same values over and over
    if (
      this.isDirty &&
      consentedToEmailMarketing === this.consentedToEmailMarketing
    ) {
      return
    }
    try {
      const { statusCode } = await putEmailMarketingPreference.exec({
        profileId: this.profileId,
        promptForMarketingConsent: false,
        email: consentedToEmailMarketing,
        changedBy: this.changedBy,
      })

      if (statusCode === 200) {
        this.promptForEmailMarketingConsent = false
        this.consentedToEmailMarketing = consentedToEmailMarketing
        this.isDirty = true
      }
    } catch (ex) {
      console.error('Exception during updating marketing preferences')
      console.error(ex)
    }
  },
  resetConsentStore() {
    this.promptForEmailMarketingConsent = null
    this.isDirty = false
    this.consentedToEmailMarketing = false
  },
  persist: {
    id: 'consent',
    storage: sessionStorage,
  },
})
