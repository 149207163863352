import { defineStore } from 'pinia'

export const useDeviceStore = defineStore('deviceStore', {
  state: () => ({
    country: '',
    countryId: 105,
  }),

  actions: {
    setCountry (country) {
      if (country) this.country = country
    },
    setCountryId (id) {
      if (id) this.countryId = id
    },
  },
})
