import { defineStore } from 'pinia'
import { useEnvStore } from '~/store/env'

export const useAppStore = defineStore('appStore', {
  state: () => ({
    xeVersion: '',
    loadedScripts: {},
  }),

  actions: {
    setVersion() {
      const envStore = useEnvStore()
      const app_version = envStore[envStore.getVariable('VUE_APP_XE_VERSION')]
      this.xeVersion = app_version || 'Development'
      console.log('Xe version: ', this.xeVersion)
    },

    async loadedScript(url) {
      const scripts = this.loadedScripts
      if (scripts[url]) return scripts[url]

      const promise = new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.defer = true
        script.src = url
        script.onload = () => resolve()
        script.onerror = () => {
          scripts[url] = null
          reject()
        }
        document.body.appendChild(script)
      })
      scripts[url] = promise
      return promise
    },

    log(logMessage) {
      // TODO !!!!!!!!!!!!!!!!!!!!!!!
      console.log(logMessage)
    },
    logException({ text, exception }) {
      // TODO !!!!!!!!!!!!!!!!!!!!!!!
      console.error('EXCEPTION: ' + text)
      console.error(exception)
    },
  },
})
