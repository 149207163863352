import type { Locale } from 'vue-i18n'
import { createI18n } from 'vue-i18n'
import { getLocalesInfo } from '~/Helpers/localiseHelper'

// Import i18n resources
// https://vitejs.dev/guide/features.html#glob-import
//
// Don't need this? Try vitesse-lite: https://github.com/antfu/vitesse-lite
const i18n = createI18n({
  legacy: false,
  locale: '',
  messages: {},
  globalInjection: true,
})

export async function setI18n() {
  const locale = await loadLanguageAsync()
  return { userLocale: locale, i18n }
}

function setI18nLanguage(lang: Locale) {
  i18n.global.locale.value = lang as any
  if (typeof document !== 'undefined') {
    document.querySelector('html')?.setAttribute('lang', lang)
  }
  return lang
}

export async function loadLanguageAsync(): Promise<Locale> {
  const { locale, localePath } = getLocalesInfo()

  if (i18n.global.availableLocales.includes(locale)) {
    return setI18nLanguage(locale)
  }

  const messages = await localePath()
  i18n.global.setLocaleMessage(locale, messages.default)
  return setI18nLanguage(locale)
}
